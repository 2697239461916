import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/portfolio/PathologyS.png'
import IMG2 from '../../assets/portfolio/HousingPricesS.png'
import IMG3 from '../../assets/portfolio/BachelorS.jpg'
import IMG4 from '../../assets/portfolio/DecisionTreeS.png'
import IMG5 from '../../assets/portfolio/DigitRecognizer.png'
import IMG6 from '../../assets/portfolio/NetworkDesign.png'
import Thesis from '../../assets/portfolio/BachelorsThesis.pdf'
import Metaheuristics from '../../assets/portfolio/Metaheuristics.png'
import ThesisImage from '../../assets/portfolio/MastersThesis.png'

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {/* ========== PORTFOLIO ITEMS ========== */}

            <article className='portfolio__item'>
              <div className='portfolio__item-image'>
                <img src={ThesisImage} alt="Master's Thesis - Optimization of Health Service Logistics" />
              </div>
              <h3>Ongoing Master's Thesis - Optimization of Health Service Logistics</h3>
              <div className="portfolio__item-cta">
                <a href='https://www.vestnytt.no/nyheter/i/Eym3ka/vil-gjera-koeyreplanane-til-heimetenesta-meir-robust' className='btn' target='_blank' rel="noreferrer">
                  Article in Vestnytt (Paywall)
                </a>
              </div>
            </article>

            <article className='portfolio__item'>
              <div className='portfolio__item-image'>
                <img src={Metaheuristics} alt="ALNS Algorithm for PDPTW" />
              </div>
              <h3>ALNS Algorithm for PDPTW</h3>
              <div className="portfolio__item-cta">
                <a href='https://github.com/bjerkvik/Metaheuristics-INF273' className='btn' target='_blank' rel="noreferrer">Github</a>
              </div>
            </article>

            <article className='portfolio__item'>
              <div className='portfolio__item-image'>
                <img src={IMG4} alt='Decision Tree Classifier from Scratch' />
              </div>
              <h3>Decision Tree Classifier from Scratch</h3>
              <div className="portfolio__item-cta">
                <a href='https://github.com/bjerkvik/DecisionTreeFromScratch-INF264' className='btn' target='_blank' rel="noreferrer">Github</a>
              </div>
            </article>

            <article className='portfolio__item'>
              <div className='portfolio__item-image'>
                <img src={IMG5} alt='Hexadecimal Digit Recognizer' />
              </div>
              <h3>Hexadecimal Digit Recognizer</h3>
              <div className="portfolio__item-cta">
                <a href='https://github.com/bjerkvik/DigitRecognizer-INF264' className='btn' target='_blank' rel="noreferrer">Github</a>
              </div>
            </article>

            <article className='portfolio__item'>
              <div className='portfolio__item-image'>
                <img src={IMG6} alt='Supply Chain Network Design' />
              </div>
              <h3>Supply Chain Network Design</h3>
              <div className="portfolio__item-cta">
                <a href='https://github.com/bjerkvik/SupplyChainNetworkDesign-INF170' className='btn' target='_blank' rel="noreferrer">Github</a>
              </div>
            </article>  

            <article className='portfolio__item'>
              <div className='portfolio__item-image'>
                <img src={IMG2} alt='Predict House Prices using ML' />
              </div>
              <h3>Predict House Prices using ML</h3>
              <div className="portfolio__item-cta">
                <a href='https://www.kaggle.com/code/trymbjerkvik/dat158-assignment-1' className='btn' target='_blank' rel="noreferrer">Kaggle</a>
              </div>
            </article>   

            <article className='portfolio__item'>
              <div className='portfolio__item-image'>
                <img src={IMG1} alt='Deep Learning Pathology Cancer Detector' />
              </div>
              <h3>Deep Learning Pathology - Cancer Detector</h3>
              <div className="portfolio__item-cta">
                <a href='https://github.com/bjerkvik/dat255-deep-learning-pathology' className='btn' target='_blank' rel="noreferrer">Github</a>
                <a href='https://huggingface.co/spaces/trymbjerkvik/histopath-cancer-detector' className='btn btn-primary' target='_blank' rel="noreferrer">Live Demo</a>
              </div>
            </article> 

            <article className='portfolio__item'>
              <div className='portfolio__item-image'>
                <img src={IMG3} alt="Bachelor's Thesis - Vessel Data Dashboard" />
              </div>
              <h3>Bachelor's Thesis - Vessel Data Dashboard</h3>
              <div className="portfolio__item-cta">
                <a href={Thesis} download className='btn'>Download PDF</a>
              </div>
            </article>  
      </div>
    </section>
  )
}

export default Portfolio